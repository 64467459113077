var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "make",
                  attrs: {
                    placeholder: _vm.$t("applications.enter-vehicle-make"),
                    required: "",
                    icon: "directions_car",
                    label: _vm.$t("applications.make"),
                    name: "make",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.make-required")
                      }
                    ],
                    value: _vm.model.make
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("make", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "model",
                  attrs: {
                    placeholder: _vm.$t("applications.enter-vehicle-model"),
                    required: "",
                    icon: "directions_car",
                    value: _vm.model.model,
                    name: "model",
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.model-required")
                      }
                    ],
                    label: _vm.$t("applications.model")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("model", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "year",
                  attrs: {
                    label: _vm.$t("applications.year"),
                    required: "",
                    placeholder: "yyyy",
                    mask: "####",
                    "return-masked-value": "",
                    icon: "calendar_today",
                    name: "year",
                    value: _vm.model.year,
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("applications.year-required")
                      }
                    ]
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("year", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "plate",
                  attrs: {
                    label: _vm.$t("applications.plate-number"),
                    required: "",
                    placeholder: _vm.$t("applications.enter-plate-number"),
                    icon: "video_label",
                    name: "plate",
                    rules: [
                      function(v) {
                        return (
                          !!v || _vm.$t("applications.plate-number-required")
                        )
                      }
                    ],
                    value: _vm.model.plate
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("plate", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }