<template>
    <div>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="make"
                    :placeholder="$t('applications.enter-vehicle-make')"
                    required
                    icon="directions_car"
                    :label="$t('applications.make')"
                    name="make"
                    :rules="[(v) => !!v || $t('applications.make-required')]"
                    :value="model.make"
                    @blur="update('make', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="model"
                    :placeholder="$t('applications.enter-vehicle-model')"
                    required
                    icon="directions_car"
                    :value="model.model"
                    name="model"
                    :rules="[(v) => !!v || $t('applications.model-required')]"
                    :label="$t('applications.model')"
                    @blur="update('model', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="year"
                    :label="$t('applications.year')"
                    required
                    placeholder="yyyy"
                    mask="####"
                    return-masked-value
                    icon="calendar_today"
                    name="year"
                    :value="model.year"
                    :rules="[(v) => !!v || $t('applications.year-required')]"
                    @blur="update('year', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="plate"
                    :label="$t('applications.plate-number')"
                    required
                    :placeholder="$t('applications.enter-plate-number')"
                    icon="video_label"
                    name="plate"
                    :rules="[
                        (v) => !!v || $t('applications.plate-number-required'),
                    ]"
                    :value="model.plate"
                    @blur="update('plate', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>
<script>
export default {
    name: "applicant-vehicle",
    props: {
        modelData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return ["model", "make", "id", "year", "plate"].every((elem) =>
                    params.includes(elem)
                );
            },
        },
    },
    data() {
        return {
            model: this.modelData,
        };
    },
    methods: {
        update(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
};
</script>

<style scoped>
</style>
